import { inject, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';

import { ApiService } from '@features/api';

import { CustomPage, CustomPageNavigation } from '../models';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomPagesService {
  #api = inject(ApiService);

  readonly navigation = toSignal(
    this.#api.get<{ data: CustomPageNavigation }>('/navigation').pipe(map(({ data }) => data)),
    {
      initialValue: {
        menu_items: [],
        footer_items: [],
      },
    },
  );

  page(slug: string) {
    return this.#api.get<{ data: CustomPage }>(`/custom-pages/${slug}`).pipe(map(({ data }) => data));
  }
}
