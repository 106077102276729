<div class="max-w-screen-lg mx-auto p-8 text-white pt-16">
  @if(loaded()) {
  <h1 class="text-6xl font-bold mb-8">{{ page()?.title }}</h1>

  <div class="flex flex-col gap-8">
    @for(item of page()?.content; track $index) {
    <ng-container appCustomPageBlock [data]="item.data" [type]="item.type"></ng-container>
    }
    <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
      @for(child of page()?.children; track $index) {

      @if(child.children.length) {
      <div class="flex flex-col gap-4 col-span-4 items-start">
        <a [routerLink]="['/pages', child.slug]" class="font-bold text-primary text-2xl">{{ child.title }}</a>

        <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
          @for(grandchild of child.children; track $index) {
          <ng-container [ngTemplateOutlet]="link"
            [ngTemplateOutletContext]="{ title: grandchild.title, slug: grandchild.slug }"></ng-container>
          }
        </div>
      </div>
      }
      @else {
      <ng-container [ngTemplateOutlet]="link"
        [ngTemplateOutletContext]="{ title: child.title, slug: child.slug }"></ng-container>
      }}
    </div>
  </div>
  } @else {
  <div class="flex flex-row items-center justify-center">
    <app-spinner></app-spinner>
  </div>
  }
</div>

<ng-template #link let-title="title" let-slug="slug">
  <a class="flex flex-col gap-4 items-center" [routerLink]="['/pages', slug]">
    <img src="/assets/images/what-is-bongos-bingo.jpg" alt="" class="aspect-square rounded-full object-cover">
    <p class="leading-tight text-center font-bold text-lg">{{ title }}</p>
  </a>
</ng-template>