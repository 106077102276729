import { Component, Directive, effect, inject, input, signal, Type, ViewContainerRef } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { finalize, switchMap, tap } from 'rxjs';

import { SpinnerComponent } from '@components/spinner';

import { CustomPagesService } from '../../services';
import { CustomPageComponentsMap } from '../../models';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

/**
 * Base class for all custom page block components.
 * Used to enforce the data property to be present and type safe.
 */
@Component({ template: '' })
abstract class CustomPageBlockComponent<T extends keyof CustomPageComponentsMap> {
  data = input.required<CustomPageComponentsMap[T]>();
}

/**
 * Display a heading with the specified level and content.
 */
@Component({
  selector: 'app-custom-page-heading',
  template: `
    <div
      class="[&>h2]:text-4xl [&>h3]:text-3xl [&>h4]:text-2xl [&>h5]:text-xl [&>h6]:text-lg [&>*]:leading-loose [&>*]:font-bold"
      innerHTML="<{{ data().level }}>{{ data().content }}</{{ data().level }}>"
    ></div>
  `,
})
export class CustomPageHeadingComponent extends CustomPageBlockComponent<'heading'> {}

/**
 * Display content as HTML.
 */
@Component({
  selector: 'app-custom-page-content',
  template: `<div class="leading-loose text-justify" [innerHTML]="data().content"></div>`,
})
export class CustomPageContentComponent extends CustomPageBlockComponent<'content'> {}

/**
 * Display an image with the specified URL and alt text and figure caption.
 */
@Component({
  selector: 'app-custom-page-image',
  template: `<figure>
    <img [src]="data().url" [alt]="data().alt" [class.w-full]="data().is_full_width" class="mb-2 mx-auto" />
    <figcaption class="text-center italic">{{ data().alt }}</figcaption>
  </figure>`,
})
export class CustomPageImageComponent extends CustomPageBlockComponent<'image'> {}

/**
 * Display a video with the specified URL and alt text.
 */
@Component({
  selector: 'app-custom-page-video',
  template: `<video controls [src]="data().url" [class.w-full]="data().is_full_width" class="mx-auto"></video>`,
})
export class CustomPageVideoComponent extends CustomPageBlockComponent<'video'> {}

/**
 * Display a URL button with the specified URL and label.
 * If is_external is true, the link will open in a new tab.
 */
@Component({
  selector: 'app-custom-page-url-button',
  template: `<div class="flex flex-row items-center justify-center">
    <a [href]="data().url" class="primary-button" [attr.target]="data().is_external ? '_blank' : null">
      {{ data().label }}
    </a>
  </div>`,
})
export class CustomPageUrlButtonComponent extends CustomPageBlockComponent<'url_button'> {}

/**
 * Display an unordered list with the specified items.
 */
@Component({
  selector: 'app-custom-page-unordered-list',
  template: `<ul class="list-disc list-inside">
    @for (child of data().content; track $index) {
      <li>{{ child.item }}</li>
    }
  </ul>`,
})
export class CustomPageUnorderedListComponent extends CustomPageBlockComponent<'unordered_list'> {}

/**
 * Map of custom page block types to their corresponding components.
 */
const components: { [T in keyof CustomPageComponentsMap]: Type<CustomPageBlockComponent<T>> } = {
  heading: CustomPageHeadingComponent,
  content: CustomPageContentComponent,
  image: CustomPageImageComponent,
  video: CustomPageVideoComponent,
  url_button: CustomPageUrlButtonComponent,
  unordered_list: CustomPageUnorderedListComponent,
};

/**
 * Directive to dynamically create a custom page block component based on the type.
 */
@Directive({
  selector: '[appCustomPageBlock]',
  standalone: true,
})
export class CustomPageBlockDirective<T extends keyof CustomPageComponentsMap> {
  type = input.required<T>();
  data = input.required<CustomPageComponentsMap[T]>();

  protected vcr = inject(ViewContainerRef);

  constructor() {
    effect(() => {
      if (this.vcr.length) {
        this.vcr.clear();
      }

      const component = components[this.type()];
      const ref = this.vcr.createComponent(component);
      ref.instance.data = this.data;
    });
  }
}

/**
 * Component to display a custom page with dynamic blocks.
 */
@Component({
  selector: 'app-custom-page',
  templateUrl: './custom-page.component.html',
  styleUrls: ['./custom-page.component.scss'],
  imports: [CommonModule, RouterModule, CustomPageBlockDirective, SpinnerComponent],
  standalone: true,
})
export class CustomPageComponent {
  #service = inject(CustomPagesService);

  protected slug = input.required<string>();

  protected loaded = signal(false);

  protected page = toSignal(
    toObservable(this.slug).pipe(
      tap(() => this.loaded.set(false)),
      switchMap((slug) => this.#service.page(slug).pipe(finalize(() => this.loaded.set(true)))),
    ),
  );
}
